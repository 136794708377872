import gql from "graphql-tag";
import * as Roles from "@/security/rolesHelper";

export const GET_TEAMS = gql`
  query getTeams {
    teams(order_by: { name: asc }) {
      id
      name
      status
      locations {
        specialistID
        partners_aggregate {
          aggregate {
            count
          }
        }
      }
      accountManager {
        id
        firstName
        lastName
      }
      teamManagers {
        manager {
          id
          firstName
          lastName
          clientsManaged_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      teamSpecialists {
        employee {
          id
          firstName
          lastName
        }
        locations_aggregate {
          aggregate {
            count
          }
        }
        locations {
          clientID
          partners_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;

export const GET_TEAM = gql`
  query getTeam($id: uuid!) {
    teams_by_pk(id: $id) {
      id
      name
      status
      locations {
        id
        name
        clientID
        specialistID
        partners_aggregate {
          aggregate {
            count
          }
        }
      }
      accountManager {
        id
        firstName
        lastName
      }
      teamManagers {
        manager {
          id
          firstName
          lastName
          clientsManaged {
            client {
              locations {
                id
                name
                clientID
                teamSpecialist {
                  id
                }
                partners_aggregate {
                  aggregate {
                    count
                  }
                }
              }
            }
          }
          clientsManaged_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      teamSpecialists {
        id
        employee {
          id
          firstName
          lastName
        }
        locations_aggregate {
          aggregate {
            count
          }
        }
        locations {
          id
          name
          clientID
          partners_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;

export const GET_AVAILABLE_ACCOUNT_MANAGERS = gql`
    query getAvailableAccountManagers {
        employees(where: {role: {_eq: ${Roles.ACCOUNT_MANAGER}}}) {
            id
            firstName
            lastName
            accountManager {
                id
            }
        }
    } 
`;

export const GET_AVAILABLE_ONBOARDING_SPECIALISTS = gql`
    query getAvabilableOnboardingSpecialists {
      employees(where: {role: {_eq: ${Roles.ONBOARDING_SPECIALIST}}}) {
        id
        firstName
        lastName
        specialist {
          id
        }
      }
    }
`;

export const GET_EMPLOYEES_FOR_ROLE = gql`
  query getEmployeesForRole($role: roles_enum) {
    employees(where: { role: { _eq: $role } }) {
      id
      firstName
      lastName
      clientsManaged {
        id
        client {
          id
          locations {
            id
            name
            team {
              id
            }
            teamSpecialist {
              id
              employee {
                id
                firstName
                lastName
              }
              locations {
                id
                name
                partners_aggregate {
                  aggregate {
                    count
                  }
                }
              }
            }
            clientID
            partners_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
  }
`;

export const SAVE_TEAM = gql`
  mutation saveTeam($team: teams_insert_input!) {
    insert_teams_one(object: $team, on_conflict: { constraint: teams_pkey, update_columns: [name, employeeID] }) {
      id
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($id: uuid!, $team: teams_set_input!) {
    update_teams_by_pk(pk_columns: { id: $id }, _set: $team) {
      id
    }
  }
`;

export const SAVE_TEAM_MANAGERS = gql`
  mutation saveTeamManagers($teamManagers: [teamManagers_insert_input!]!, $teamID: uuid!, $employeeIds: [uuid!]!) {
    delete_teamManagers(where: { _and: [{ teamID: { _eq: $teamID } }, { employeeID: { _nin: $employeeIds } }] }) {
      affected_rows
    }
    insert_teamManagers(objects: $teamManagers, on_conflict: { constraint: teamManagers_teamID_employeeID_key, update_columns: [] }) {
      affected_rows
    }
  }
`;

export const SAVE_ONBOARDING_SPECIALISTS = gql`
  mutation saveOnboardingSpecialists($teamSpecialists: [teamSpecialists_insert_input!]!, $teamID: uuid!, $specialistIds: [uuid!]!, $onboardingSpecialistIds: [uuid!]!) {
    update_clientLocations(where: { _and: [{ teamID: { _eq: $teamID } }, { specialistID: { _nin: $specialistIds } }] }, _set: { specialistID: null }) {
      affected_rows
    }
    delete_teamSpecialists(where: { _and: [{ teamID: { _eq: $teamID } }, { employeeID: { _nin: $onboardingSpecialistIds } }] }) {
      affected_rows
    }
    insert_teamSpecialists(objects: $teamSpecialists, on_conflict: { constraint: teamSpecialists_teamID_employeeID_key, update_columns: [] }) {
      returning {
        id
        employeeID
      }
    }
  }
`;

export const UPDATE_LOCATIONS = gql`
  mutation updateLocations($id: uuid!, $update: clientLocations_set_input) {
    update_clientLocations_by_pk(_set: $update, pk_columns: { id: $id }) {
      id
    }
  }
`;

export const REMOVE_UNUSED_LOCATIONS = gql`
  mutation updateLocations($teamID: uuid!, $locationIds: [uuid!]!) {
    update_clientLocations(where: { _and: [{ specialistID: { _is_null: true } }, { teamID: { _eq: $teamID } }, { id: { _nin: $locationIds } }] }, _set: { teamID: null }) {
      affected_rows
    }
  }
`;

export const REMOVE_UNUSED_SPECIALIST_LOCATIONS = gql`
  mutation updateLocations($specialistID: uuid!, $locationIds: [uuid!]!) {
    update_clientLocations(where: { _and: [{ specialistID: { _eq: $specialistID } }, { id: { _nin: $locationIds } }] }, _set: { specialistID: null }) {
      affected_rows
    }
  }
`;
